<template>
  <div class="winXP-wrap" @click="handleClick">
<!--    <input class="winXP-btn" type="button" v-model="title" />-->
    <button class="winXP-btn" :disabled="disabled" >{{title}}</button>
  </div>
</template>

<script>
export default {
  name: 'XPButton',
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    title: {
      default: 'Update',
    }
  },
  methods: {
    handleClick(event) {
      this.$emit('handle_click_action', event);
    }
  }
}
</script>

<style scoped>

  /*   start Button CSS    */

  button:disabled, button:disabled:hover, button:disabled:active,
  input[type=button]:disabled, input[type=button]:disabled:hover, input[type=button]:disabled:active{
    background-color: rgb(204,204,204);
    /*197,197,197*/
    -webkit-box-shadow: 0px 0px 0px 1px rgb(204,204,204),
    0px 0px 0px 2px rgb(173,173,173);
    box-shadow: 0px 0px 0px 1px rgb(204,204,204),
    0px 0px 0px 2px rgb(173,173,173);
    color: rgb(135,135,135);
  }

  button, input[type=button]{
    outline: none;
    -webkit-transition: background-color .5s, -webkit-box-shadow .5s;
    transition: background-color .5s, -webkit-box-shadow .5s;
    -o-transition: box-shadow .5s, background-color .5s;
    transition: box-shadow .5s, background-color .5s;
    transition: box-shadow .5s, background-color .5s, -webkit-box-shadow .5s;
    font-family: sans-serif;
    font-size: 13px;
    background-color: rgb(225,225,225);
    padding: 3px 10px;
    margin: 2px;
    border: 0;
    -webkit-box-shadow: 0px 0px 0px 1px rgb(225,225,225),
    0px 0px 0px 2px rgb(173,173,173);
    box-shadow: 0px 0px 0px 1px rgb(225,225,225),
    0px 0px 0px 2px rgb(173,173,173);
    border: 1px dotted transparent;
    color: black;
  }

  button:focus, input[type=button]:focus{

    background-color: rgb(225,225,225);
    -webkit-box-shadow: 0px 0px 0px 0px rgb(225,225,225),
    0px 0px 0px 2px rgb(0,120,215);
    box-shadow: 0px 0px 0px 0px rgb(225,225,225),
    0px 0px 0px 2px rgb(0,120,215);
    border: 1px dotted rgb(17,17,17);
  }

  button:hover, input[type=button]:hover{
    -webkit-box-shadow: 0px 0px 0px 1px rgb(225,225,225),
    0px 0px 0px 2px rgb(0,120,215);
    box-shadow: 0px 0px 0px 1px rgb(225,225,225),
    0px 0px 0px 2px rgb(0,120,215);
    background-color: rgb(229,241,251);
  }
  button:active, input[type=button]:active{
    -webkit-transition: background-color 0s;
    -o-transition: background-color 0s;
    transition: background-color 0s;
    background-color: rgb(204,228,247);
    -webkit-box-shadow: 0px 0px 0px 1px rgb(225,225,225),
    0px 0px 0px 2px rgb(0,120,215);
    box-shadow: 0px 0px 0px 1px rgb(225,225,225),
    0px 0px 0px 2px rgb(0,120,215);
  }

  /*    end Button CSS     */

</style>
