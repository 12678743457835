<template>
  <div id="mycard">
    <div class="card mb-3" style="max-width: 540px; min-height: 150px;">

      <div class="card-header g-0" style="background-color: whitesmoke;">
        <h3 class="card-title">{{reporting.doctor.name}}</h3>
      </div>

      <div class="card-body" >
          <p class="card-text">{{reporting.doctor.qualification}}</p>
          <p class="card-text"><small class="text-muted">{{date_title}}</small></p>
      </div>

      <div class="card-footer row">
        <div class="col">
          <button class="btn btn-warning" @click="show_no_visit_window('myCustomFunction', reporting.doctor.id)">Not Meet</button>
        </div>
        <div class="col text-right">
          <button class="btn btn-success" @click="show_yes_visit_window('promoted_products_model', reporting.doctor.id)" >Yes, Met</button>
        </div>
      </div>

    </div>

    <vmodal :name="`${reporting.doctor.id}_novisit_window`" transition="nice-modal-fade" :delay="100" :resizable="true" :reset="true" width="90%" height="456px" >
      <DoctorNoVisit  :reporting="reporting"  v-on:doctor_novisit_updated="close_no_modal()" ></DoctorNoVisit>
    </vmodal>

    <vmodal :name="`${reporting.doctor.id}_yesvisit_window`" transition="nice-modal-fade" :delay="100" :resizable="true" :reset="true" width="90%" height="525px"  >
      <DoctorYesVisit  :reporting="reporting"
                       @doctor_visit_yes_updated="close_yes_modal"
                       @cme_updated="handle_cme_update"
                       @pending_cme_updated="handle_pending_cme_update"
                       @discussion_updated="handle_discussion_update"
                       @gift_updated="handle_gift_update"
      ></DoctorYesVisit>
    </vmodal>

    <!-- The Modal -->
    <div class="modal" id="product_modal" >
      <div class="modal-dialog modal-dialog-scrollable" >
        <div class="modal-content" >

          <!-- Modal Header -->
          <div class="modal-header" style="background-color: whitesmoke;">
            <h2 class="modal-title">Promoted Products</h2>
            <button type="button" class="close" data-dismiss="modal">×</button>
          </div>

          <!-- Modal body -->
          <div class="modal-body">

            <div class="table-responsive table-scrollable">
              <table class="table" >
                <thead>
                  <th style="width: 40px;">#</th>
                  <th>Product</th>
                  <th style="width: 50px;">Qty</th>
                </thead>
                <tbody style="height: 750px">
                  <tr v-for="(item,idx) in products" style="padding: 0px;">
<!--                    <td style="padding: 0px;"><input name="cbProduct" type="checkbox" class="form-control" :id="item.id" :value="item.id"></td>-->
                    <td style="padding: 0px;">{{item.name}}</td>
                    <td style="padding: 0px;"><input  :id="`txt${item.id}`" :name="`${item.id}`" class="form-control" style="width: 50px;padding: 0px;" type="number" min="0" @change="item_qty_changed" ></td>
                  </tr>
                </tbody>
              </table>
            </div>

          </div>

          <!-- Modal footer -->
          <div class="modal-footer">
            <button type="button" class="btn btn-danger" @click="get_checked_products">Update</button>
          </div>

        </div>
      </div>
    </div>

  </div>
</template>

<script>
import {userService} from '@/store/auth-header.js'
import { store } from '@/store/store.js'
import DoctorNoVisit from './DoctorVisitNo';
import DoctorYesVisit from './DoctorVisitYes'

export default {
  name: 'DoctorVisit',
  store,
  components:{
    DoctorNoVisit,
    DoctorYesVisit,
  },
  props:{
    reporting : {
      type :Object,
      default:() => JSON.parse('{"id":0,"work_date":"","doctor":{"id":0,"name":"Doctor Name","hospital":"","sex":"M","address":"","area_id":1,"qualification":"qualification","speciality":"","class":"","dob":"1900-01-01","dow":"","no_of_visits":0,"last_visit":"2000-01-01","mobile":"","email":"","web":"","whatsapp":"","spouse_name":"","spouse_dob":"","spouse_profession":"","data":"","area":{"id":1,"name":""},"categories":[]},"visit_time":"","visited":false,"not_met_type":"","not_met_remarks":"","lbl_reminder":false,"sample_reminder":false,"cmc_reminder":false,"products":[],"promotions":[],"cme":{"title":"","comment":""},"gift":{"title":"","emoji":"","comment":""},"discussion":{"title":"","commitment":""},"pending_cme":{"id":"","title":"","comment":""}}')
    }
  },
  data(){
    return {
      userFuncs: {},
      products: [],
      working_date : moment(),
      date_title : '',
      reportings : [],
    }
  },
  created () {
    const self = this;
    self.$data.working_date = self.$store.state.user.working_date;
    self.$data.date_title = moment(self.reporting.doctor.last_visit).format('ll');

    this.$store.state.user.products.forEach( (item) => {
      if(item.id > 1) {
        let product =  { "id": item.id, "name":item.name };
        this.$data.products.push(product);
      }
    });

    $("#product_modal").on('shown.bs.modal', function () {
      // alert('The modal is displayed completely!');
    });

  },
  mounted () {
    const self = this;
  },
  methods:{
    show_no_visit_window (name, id) {
      this.$modal.show(id +`_novisit_window`);
    },
    show_yes_visit_window(name,id){
      this.$modal.show(id +`_yesvisit_window`);
    },
    close_no_modal(){
      const self = this;
      // console.log(JSON.stringify(self.$props.reporting));
      this.$modal.hide(this.$props.reporting.doctor.id +`_novisit_window`);
      this.perform_save_action();
    },
    handle_cme_update(cme){
      this.$props.reporting.cme = cme;
      // alert('handle_cme_update ' + JSON.stringify(cme) );
    },
    handle_gift_update(gift){
      this.$props.reporting.input = gift;
      // alert('handle_gift_update');
    },
    handle_discussion_update(discussion){
      this.$props.reporting.discussion = discussion
      // alert('handle_discussion_cme_updated');
    },
    handle_pending_cme_update(pending_cme){
      alert('handle_pending_cme_updated');
    },
    beforeClose(){
    },
    beforeOpen(){
    },
    close_yes_modal(rep){
      const self = this;
      // console.log(JSON.stringify(rep));
      self.perform_save_action();

      self.$modal.hide( self.$props.reporting.doctor.id +`_yesvisit_window`);
    },
    item_qty_changed(evt){
      try {
        let qty = parseInt(document.getElementById(evt.target.id).value);
        if(qty > 0) {
          document.getElementById(evt.target.name).checked = true;
        }else{
          document.getElementById(evt.target.name).checked = false;
        }

      }catch (e) {
        alert(e);
      }
    },
    get_checked_products(){
      const self = this;
      try {
        self.reporting.promoted_products = [];
        let checkboxes = document.getElementsByName('cbProduct');
        for (let i = 0; i < checkboxes.length; i++) {
          if (checkboxes[i].checked) {

            self.$store.state.user.products.forEach( (item) => {
              if(item.id ==  checkboxes[i].value) {
                self.reporting.promoted_products.push(item);
              }
            });
            //alert(checkboxes[i].value);
          }
        }
      }catch (e) {
        alert(e);
      }
    },
    perform_save_action(){
      const self = this;

      $('#mycard').block({
        msg: '<i class="icon-spinner2 spinner"></i>',
        overlayCSS: {
          backgroundColor: '#fff',
          opacity: 0.8,
          cursor: 'wait',
          'box-shadow': '0 0 0 1px #ddd'
        },
        css: {
          border: 0,
          padding: 0,
          backgroundColor: 'none'
        }
      });

      let mydata =  JSON.parse(JSON.stringify(self.$props.reporting));
      delete mydata.products;

      console.log(JSON.stringify(mydata));

      const requestOptions = {
        method: 'POST',
        mode: 'cors',
        headers: userService.authHeader(),
        body: JSON.stringify(mydata)
      }

      fetch(`${process.env.VUE_APP_ROOT_API}api/reporting/doctor`, requestOptions).then(userService.handleResponse).then(function (resp) {
        if (resp.ok) {
          swal({ title: 'Success!', text: resp.msg, type: 'success'});
        } else {
          swal({ title: 'Oops!', text: resp.msg, type: 'error', timer: 3000 });
        }
        $('#mycard').unblock();

      }).catch(function (err) {
        swal({ title: 'Oh noes!', text: err.toString(), type: 'error', timer: 3000 });
      }).finally(function () {
        $('#mycard').unblock();
      });

    }
  }
}
</script>

<style scoped>
  .table-scrollable {
    /*height: 547px; !* Set a fixed height for the scrollable area *!*/
    overflow-y: scroll; /* Enable vertical scrolling */
  }
  input:focus {
    background: #feff00;
  }

  textarea:focus {
    background: #feff00;
  }

  select:focus {
    background: #feff00;
  }

  button:focus {
    background: #feff00;
    color: #0a0a0a!important;
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type=number] {
    -moz-appearance: textfield;
  }
</style>
