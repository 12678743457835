<template>
  <button class="win10-button">
    <svg viewBox="0 0 24 24" class="icon">
      <path d="M17 3H5a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2V7l-4-4zM12 19a2.5 2.5 0 1 1 0-5 2.5 2.5 0 0 1 0 5zM16 9H8V5h8v4z"/>
    </svg>
    <slot/>
  </button>
</template>

<script>
export default {
  name: 'W10Button'
}
</script>

<style scoped>
  .win10-button {
    display: flex;
    align-items: center;
    gap: 8px;
    padding: 10px 20px;
    background-color: #0078d7;
    color: white;
    font-size: 16px;
    font-weight: 500;
    border: none;
    border-radius: 2px;
    cursor: pointer;
    outline: none;
    transition: background-color 0.2s ease, box-shadow 0.2s ease;
  }

  .win10-button:hover {
    background-color: #0063b1;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  }

  .win10-button:active {
    background-color: #005599;
  }

  .icon {
    width: 20px;
    height: 20px;
    fill: currentColor;
  }
</style>
